.container {
    /* background-color: #f8d7da44; */
    color:#ccc;
    font-family: 'Roboto' , sans-serif;
    border-radius: 4px;
    max-width: 400px;
    margin:  auto;
    text-align: center;
  }
  
  .message {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .errorDetails {

    padding: 10px;
    border-radius: 4px;
    color: #721c256b;
    font-family: monospace;
    overflow-x: auto;
    white-space: pre-wrap;
    margin-bottom: 15px;
  }
  
  .retryButton {
    font-family: 'Roboto' , sans-serif;
    font-weight: bold;
    background-color: #c49411;
    color: #ffeebe;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
  }
  
  .retryButton:hover {
    background-color: #c49411cc;
}
  .errorImage {
    width: 20rem;
    height: auto;
  }
  