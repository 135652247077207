.loadingText{
    color:#767782;
    position: absolute;
    top:5rem;
    left:50%;
    display: flex;
    transform: translateX(-50%);
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

/* Takes up the entire viewport */
.loadingWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(14, 14, 14, 0.137); /* Optional semi-transparent background */
}

.loader2 {
    width: 50px; /* Adjust size as needed */
    height: 50px;
    border: 4px solid rgba(172, 172, 172, 0.075); /* Outer gray ring */
    border-top: 4px solid grey; /* Loader color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Spinning animation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.loader {
    --d: 8px; /* Adjusted to 8px for a smaller loader size */
    width: 1px; /* Width of each dot */
    height: 1px; /* Height of each dot */
    border-radius: 50%;
    position: relative;
    color: #919191;
    box-shadow: 
      calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
      calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
      calc(0 * var(--d)) calc(1 * var(--d)) 0 1px,
      calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
      calc(-1 * var(--d)) calc(0 * var(--d)) 0 1px,
      calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 1.5px,
      calc(0 * var(--d)) calc(-1 * var(--d)) 0 1.7px;
    animation: l27 1s infinite steps(8);
    cursor: none !important;
    top:0.5rem;
    left: 0.7rem;
}

@keyframes l27 {
    100% { transform: rotate(1turn); }
}
