body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Bank Gothic';
  src: url('./assets/fonts/bank\ gothic\ medium\ bt.ttf') format('truetype'); /* Update the path based on your directory structure */
}


@font-face {
  font-family: 'Bank Gothic';
  src: url('./assets/fonts/bank\ gothic\ medium\ bt.ttf') format('truetype'); /* Update the path based on your directory structure */
}


@font-face {
  font-family: 'BankGothicBold';
  src: url('./assets/fonts/BankGothic\ Bold.ttf') format('truetype'); /* Update the path based on your directory structure */
}





/* WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 4px; /* Height of the scrollbar (for horizontal scrollbars) */
}

::-webkit-scrollbar-thumb {
  background-color: #1a202c; /* Dark grey color for the thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-track {
  background-color: transparent !important; /* Transparent track background */
}

/* Firefox */
* {
  scrollbar-width: thin; /* Make the scrollbar skinny */
  scrollbar-color: #1a202c transparent; /* Dark grey thumb and transparent track */
}

/* For older versions of Firefox */
*::-moz-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 4px; /* Height of the scrollbar (for horizontal scrollbars) */
}

*::-moz-scrollbar-thumb {
  background-color: #1a202c; /* Dark grey color for the thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.scroller {
  scrollbar-color: #0a50db transparent;
}

*::-moz-scrollbar-track {
  background-color: transparent; /* Transparent track background */
}
