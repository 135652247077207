:root {
    --text-primary: #b6b6b6;
    --text-secondary: #DFDFDF;
    --bg-primary: #161624;
    --bg-secondary: #141418;
}

.navbar-v3 {
    width: 100%;
    height: 40px;
    position: fixed;
    background-color: #0b0b1c;
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    font-family: 'Roboto', sans-serif;
    z-index: 999;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

}

.dashboard-icon:hover {
    cursor: pointer;
}

.add-element-wrapper {
    cursor: pointer;
    display: flex;
    align-items: inherit;
    flex-direction: row;
    color: var(--text-secondary);
    font-weight: 600;
    border: 1px solid #FFFCFC5C;
    font-size: 14px;
    align-items: center;
    justify-content: space-around;
    padding: 3px 3px;
    border-radius: 2px;
    width: 43px;
    background: linear-gradient(0deg, rgba(102, 103, 103, 0.1) 0%, rgba(46, 45, 44, 0.12) 100%);
}

.add-element-wrapper:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.027) 0%, rgba(22, 22, 22, 0.54) 100%);
}

.navbar-right-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    right: 1.5rem;
    gap: 0.5rem;
}

.navbar-right-wrapper-1 {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

}

.navbar-right-wrapper-2 {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.4rem;
}

.add-label {
    color: var(--text-secondary);
}

.ecp-icon-wrapper {
    padding: 3px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 3px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.navbar-v3 .ecp-icon {
    width: 28px;
    filter: grayscale(10%) opacity(1) !important;

}


.ecp-icon-wrapper:hover {
    cursor: pointer;
    background-color: #cccccc21;

}

.dashboard-icon {
    display: none;
}

.ecp-icon-mobile {
    display: none;
}

@media (max-width: 600px) {
    /* .ecp-icon-mobile {
        display: block;
        width: 30px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    } */

    .dashboard-icon-select {
        display: none;
    }

    .dashboardInput-wrapper {
        padding-left: 1.1rem;
    }

    .add-element-wrapper,
    .zoom-controls,
    .separator,
    .simulator-bar-wrapper,
    .deploy-button-dashboard.dashboard {
        display: none !important;
    }


    .simulator-bar-button {
        display: none;
    }

    .navbar-right-wrapper {
        right: 3rem !important;
    }
}

.floating-button {
    display: none;
    position: fixed !important;
    bottom: 40px !important;
    right: 40px !important;
    z-index: 1001 !important;
    background-color: #1a1b35 !important;
    border: 1px solid rgb(43, 46, 87) !important;
}

.configuration-navbar {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 60px;
    align-items: center;
    gap: 5px;
}

@media ((max-height: 500px) and (max-width: 600px)) or (max-width: 600px) {
    .floating-button {
        display: inline;
    }

    .ecp-icon-mobile {
        /* display: block; */
        width: 30px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .dashboard-icon-select {
        display: none;
    }

    .dashboardInput-wrapper {
        padding-left: 1.1rem;
    }

    .add-element-wrapper,
    .separator {
        display: none;
    }

    .deploy-button-dashboard.dashboard {
        display: none !important;
    }

    .simulator-bar-button {
        display: none;
    }

    .navbar-right-wrapper {
        right: 3rem !important;
    }
}

@media (max-width: 1100px) {
    .navbar-right-wrapper {
        right: 2rem;
        gap: 1rem;
    }

    .ecp-icon-wrapper {
        display: none;
    }

    .dashboard-icon,
    .close-icon {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-65%);
    }

    .sidebar-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
        //transition: opacity 0.3s, visibility 0.3s;
        z-index: 99;
    }

    .sidebar-overlay.visible {
        opacity: 1;
        visibility: visible;
    }

    .navbar-open {
        transform: translateX(0);
        //transition: transform 0.3s ease;
    }

    .navbar-closed {
        transform: translateX(-100%);
        //transition: transform 0.3s ease;
    }

    .navbar-open .navbar-nav .link-text {
        display: inline;
        margin-left: 0;
        margin-right: 1rem;
    }
}