@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* * {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

html {
  font-size: 10px;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;

} */


.login-body {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  height: 100vh;
  background: linear-gradient(89deg, rgba(22, 21, 34, 1) 0%, rgba(22, 21, 34, 1) 49%, rgba(47, 58, 75, 1) 57%, rgba(47, 58, 75, 1) 69%, rgba(63, 94, 112, 1) 86%, rgba(63, 94, 112, 1) 100%);
}

@-moz-document url-prefix() {
  .login-body {

    background: linear-gradient(90deg, #1c1b27 0%, #1c1b27 49%, #2f3a4b 61%, #2f3a4b 70%, #3f5e70 86%, #3f5e70 100%);
  }
}

input:autofill {
  background: transparent !important;
  /* or any other */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(253, 18, 18) inset !important;
}

.login-main {
  display: flex;
  transform: translate(-50%, 0);
}

.login-left {
  /* flex-grow: 1; */
  height: 100%;


  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.login-left img {
  width: 570px;
  height: 100vh;
}

.login-right {

  height: 100vh;
  flex-grow: 1;

}

input[type="text"].invalid-input {
  border: 1px solid red;
}

input[type="password"].invalid-input {
  border: 1px solid red;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.login-right-container {
  height: 100%;
  width: 60%;
  max-width: 500px;
  min-width: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 11vw;
  bottom: 20px;
}

.login-right form {
  display: flex;
  flex-direction: column;
}

.login-logo {
  align-self: center;
  padding-top: 50px;
}

.login-center {
  margin: auto 0;
  text-align: center;
  color: #f1edff;
  border-radius: 48px;
  background: transparent;

  background: transparent;
  box-shadow: 41px 41px 82px #2f3a4b, -41px -41px 82px #1c1b27;
  padding: 38px;
}

.login-logo img {
  width: 50px;
}

.login-center h2 {
  font-size: 2.5rem;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  color: #6eabaa;
}

.login-center {
  text-align: center;
  color: #cbcbcbc2;
}

.login-center p {
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 40px;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  padding-bottom: 60px;
  color: #f1edff;
}

form input[type='email'],
form input[type='password'],
form input[type='text'],
#serial-free-select input {
  border-radius: 28px;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border: 0px;
  border: 1px solid #ffffff38;
  outline: none;
  font-weight: bold;
  box-sizing: border-box;
  background-color: #0a2f5617;
  color: white;
}

#serial-free-select input {
  margin-bottom: 0;
}

#serial-free-select {
  margin-bottom: 16px;
}

#serial-free-select button {
  background-color: unset;
  color: white;
  width: 60px;
}

#serial-free-select.container {
  width: 100%;
}

#serial-free-select button {
  border-radius: 28px;
}

#serial-free-select .dropdown-content {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #161523;
  border-radius: 15px;
  border: 1px solid #8a8a8a91;
}

.pass-input-div {
  position: relative;
}

.pass-input-div svg {
  font-size: 20px;
  position: absolute;
  right: 19px;
  bottom: 38px;
  cursor: pointer;
  outline: none;
}

form button[type='button'] {
  all: revert;
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
  font-weight: 600;

}


.login-center-options {
  display: flex;
  justify-content: space-between;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.animated-text {
  /* animation: fadeInOut 8s infinite; */
  color: #e1e1df;
  /* Example color */
  font-size: 1.5rem;
  /* Example font size */
  /* Add other styling as needed */
  top: 40vh;
  position: absolute;
  left: 50vw;
  font-weight: bold;
}

.remember-div {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.remember-div label {
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 2px;
}

.forgot-pass-link {
  text-decoration: none;
  color: #6eabaa;
  font-size: 1.1rem;
}

.forgot-pass-link:hover {
  text-decoration: underline;
}

.login-center-buttons {
  margin-top: 40px;
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 10px;
}

.login-center-buttons button:nth-child(1) {

  background-color: #3f5e70;
  border: 1px solid #f1edff;
  color: #f1edff;

}

.login-center-buttons button:nth-child(1):hover {
  color: #f1edff;
  background-color: #3f5e70;
  ;

}

.login-center-buttons button:nth-child(2) {
  background-color: #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.login-center-buttons button:nth-child(2) img {
  width: 30px;
}

.login-center-buttons button:nth-child(2):hover {
  background-color: #c4c4c457;
}

.login-bottom-p {
  text-align: center;
  font-size: 1.3rem;
  color: #f1edff;
  padding-bottom: 40px;
}

.login-bottom-p a {
  text-decoration: none;
  color: #6eabaa;
  font-weight: 600;
}

.login-bottom-p a:hover {
  text-decoration: underline;
}

.ecp-logo {
  position: absolute;
  left: 51%;

  width: 215px;

  width: 240px;

  top: 16px;

}

#SvgjsSvg1080 {
  display: none;
}

.ecp-icon-login {
  display: none;
}

@media only screen and (max-height: 450px) {

  #serial-free-select input,
  .pass-input-div input {
    font-weight: normal !important;
  }

  .login-left,
  .login-main {
    display: none;
  }

  .login-right-container {
    position: relative;
    right: 0px;
  }

  .login-center h2 {
    font-size: 2rem;

  }

  .login-center p {
    font-size: 1.3rem;

  }

  .forgot-pass-link {
    font-size: 1rem;
  }

  .login-bottom-p {
    font-size: 1.1rem;
  }

  .login-bottom-p {
    display: none;
  }

  .login-center p,
  .login-center h2 {
    position: relative;
    top: 6%;
    /* display: none; */
  }

  .ecp-icon-login {
    display: block;
    position: absolute;
    width: 50px;
    top: 8%;
    left: 50%;

    transform: translateX(-50%);
  }

  .login-body,
  .login-right,
  .login-right-container {
    height: 100%;
    width: 100%;
  }

  .login-center-buttons {
    z-index: 1000;
  }

  .login-right {
    overflow-x: hidden;
    background: transparent;
    position: absolute;
    top: -50%;
    transform: translateY(46%);
  }

  #SvgjsSvg1080 {
    /* display: block; */
    z-index: 10;
  }

  .login-center {
    box-shadow: none !important;
    padding: 0px 16px;
    padding-top: 44%;
  }

  .login-center h2,
  .forgot-pass-link {
    color: #2582ca;
  }

  .login-center-buttons button {
    background-color: #05508AC4 !important;
    border: none !important;
  }


}


@media only screen and (max-width: 600px) {

  .login-bottom-p {
    display: none;
  }

  .login-center p,
  .login-center h2 {
    position: relative;
    top: 6%;
  }

  .ecp-icon-login {
    display: block;
    position: absolute;
    width: 50px;
    top: 8%;
    left: 50%;

    transform: translateX(-50%);
  }

  .login-body,
  .login-right,
  .login-right-container {
    height: 100%;
  }

  .login-center-buttons {
    z-index: 1000;
  }

  .login-right {
    background: #0C0D17;
    position: absolute;
    top: -50%;
    transform: translateY(46%);
  }

  #SvgjsSvg1080 {
    display: block;
    z-index: 10;
  }

  .login-center {
    box-shadow: none !important;
    padding: 0px 16px;
  }

  .login-center h2,
  .forgot-pass-link {
    color: #2582ca;
  }



}

@media screen and (max-width: 700px) {


  .login-right-container {
    width: 100vw;
  }

  .login-right {
    background-color: transparent;
  }

  .login-body {
    background: #1C1B27;
  }

  .login-center {
    box-shadow: 41px 41px 82px #2f3a4b, -41px -41px 82px #2f3a4b;
  }

  .login-center-buttons button:nth-child(1) {
    background-color: #05508AC4;
    border: none
  }

  .login-center-buttons button:nth-child(1):hover {
    color: #f1edff;
    background-color: #05508A;

  }
}

@media screen and (max-width: 1100px) {

  #serial-free-select input,
  .pass-input-div input {
    font-weight: normal !important;
  }

  .login-left,
  .login-main {
    display: none;
  }

  .login-right-container {
    position: relative;
    right: 0px;
  }

  .login-center h2 {
    font-size: 2rem;

  }

  .login-center p {
    font-size: 1.3rem;

  }

  .forgot-pass-link {
    font-size: 1rem;
  }

  .login-bottom-p {
    font-size: 1.1rem;
  }
}

/* @media screen and  (max-height: 800px){
  

  .login-left {
    display: none;
  }
  .login-right-container{
    position: relative;
    right: 0px;
  }

  
} */

@media screen and (max-height: 300px) {
  .login-center h2 {
    font-size: 2rem;

  }

  .login-center p {
    font-size: 1.3rem;

  }

  .forgot-pass-link {
    font-size: 1rem;
  }

  .login-bottom-p {
    font-size: 1.1rem;
  }
}

/* Specificity to ensure autofill styles are applied */
.pass-input-div input:-webkit-autofill,
.pass-input-div input:-webkit-autofill:hover,
.pass-input-div input:-webkit-autofill:focus,
.pass-input-div input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #0a2f5617 inset !important;
  box-shadow: 0 0 0 30px #0a2f5617 inset !important;
  -webkit-text-fill-color: white !important;
  caret-color: transparent !important;
}

/* Also cover autofill state for other browsers */
.pass-input-div input:autofill {
  background-color: #0a2f5617 !important;
  color: white !important;
  caret-color: transparent !important;
}

/* Maintain consistent styles for invalid inputs */
input[type="text"].invalid-input,
input[type="password"].invalid-input {
  border: 1px solid red;
}

/* Other relevant input styles */
form input[type='email'],
form input[type='password'],
form input[type='text'],
#serial-free-select input {
  border-radius: 28px;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
  border: 0px;
  border: 1px solid #ffffff38;
  outline: none;
  font-weight: bold;
  box-sizing: border-box;
  background-color: #0a2f5617;
  color: white;
  font-family: "Inter", "Helvetica", "Arial", sans-serif;

}

#serial-free-select input {
  margin-bottom: 0;
}

#serial-free-select {
  margin-bottom: 16px;
}

#serial-free-select button {
  background-color: unset;
  color: white;
  width: 60px;
}

#serial-free-select.container {
  width: 100%;
}

#serial-free-select button {
  border-radius: 28px;
}

#serial-free-select .dropdown-content {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #161523;
  border-radius: 15px;
  border: 1px solid #8a8a8a91;
  /* From https://css.glass */
  background: rgba(22, 21, 35, 0.87);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(22, 21, 35, 0.68);
  color:#ccc;
}