.nav-link.active {
    position: relative;
    filter: grayscale(20%) opacity(0.9) !important;
}

.nav-link.active::after {
    content: '';
    position: absolute;
    right:2px;
    top: 15%;
    bottom: 15%;
    width: 3px;
    background: linear-gradient(to bottom, #2991c4, #7baec2);
    filter: grayscale(0%) opacity(1) !important;
    height: 70%;
    border-radius: 150px; /* Adjust the radius value as needed */
}


.nav-link.active svg {
    filter: grayscale(0%) opacity(1);
    fill: white;
}

/* Rest of your CSS */
.navbar {
    position: fixed;
    height: calc(100% - 39px);
    top: 40px;
    background-color: #0b0b1c;
    z-index: 1001;
    border-right: 1px solid rgba(255,255,255,0.1);
}

.navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nav-item {
    width: 100%;
}

.nav-item:last-child {
    margin-top: auto;
}

.nav-link {
    display: flex;
    align-items: center;
    height: 4.7rem;
    color: var(--text-primary);
    text-decoration: none;
    filter: grayscale(100%) opacity(0.7);
    //transition: 0.5s;
}

.nav-link:hover {
    filter: grayscale(0%) opacity(1);
    background: #35355d52;
    color: white;
}

.nav-link:hover svg {
    fill: white;
}

.link-text {
    display: none;
    margin-left: 1rem;
    font-weight: bold;
}

.nav-link svg {
    min-height: 1.5rem;
    min-width: 1.5rem;
    margin: 0 1rem;
    fill: var(--text-primary);
    //transition: fill 0.3s ease-in-out;
}

.logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
}

.logo svg {
    transform: rotate(0deg);
    //transition: transform 0.4s;
}

.navbar:hover .logo svg {
    transform: rotate(-180deg);
}

.sidebar-simulator.navbar {
    position: fixed;
    height: 100% !important;
    top: 0px;
    background-color: #16162444;
    z-index: 9999;
    display: none;
}

.sidebar-simulator.navbar:hover {
    top: 0px;
    width: 3.45rem;
}

.sidebar-simulator.navbar:hover .link-text {
    display: none;
}

@media only screen and (min-width: 1100px) {
    .navbar {
        width: 3.45rem;
        left: 0;
    }

    .navbar-lg-closed {
        display: block;
    }

    .navbar-nav {
        flex-direction: column;
        align-items: center;
        top: 5px;
        position: absolute;
    }

    .navbar:hover {
        width: 12rem;
    }

    .navbar:hover .link-text {
        display: inline;
    }

    .navbar:hover {
        width: 12rem;
    }

    .navbar:hover .link-text {
        display: block;
    }
}

/* Ensure the sidebar is displayed for smaller screens */
@media only screen and (max-width: 1100px) {
    .navbar {
        display: block;
    }
}
