html {
  height: 100%;
  text-align: center;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #07071b;
  overflow-x: auto;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #07071b;
  text-align: center;
}

/* Ensure background for all overflow areas */
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #07071b;
  z-index: -1;
  /* Send it behind all content */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mainbox {
  background-color: transparent;
  margin: auto;
  height: 600px;
  width: 600px;
  position: relative;
  color: #ffffff;

}

.err {
  color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 11rem;
  position: absolute;
  left: 20%;
  top: 8%;
  color: #ffffff;

}

.far {
  position: absolute;
  font-size: 8.5rem;
  left: 42%;
  top: 15%;
  color: #ffffff;
}

.img-not-found {
  display: none;
}

.err2 {
  color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 11rem;
  position: absolute;
  left: 68%;
  top: 8%;
}

.msg {
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.6rem;
  position: absolute;
  left: 16%;
  top: 45%;
  width: 75%;
}

.msg a {
  text-decoration: none;
  color: white;
}

.msg a:hover {
  text-decoration: underline;
}

@media (max-width: 580px) {

  .err,
  .err2,
  .far {
    display: none;
  }

  .msg {
    top: 15%;
    left: 0px;
  }

  .mainbox {
    left: 57px !important;
    width: calc(100% - 57px);
    height: 100%;
  }

  .img-not-found {
    display: block;
    left: calc(50% - 17px);
    position: relative;
  }

  /* .dashboardInput{
    min-width: 180px !important;
  } */
}


@media screen and (-webkit-min-device-pixel-ratio:0) {

  @supports (-webkit-touch-callout: none) {

    select,
    textarea,
    input {
      font-size: inherit;
    }
  }
}